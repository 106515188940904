






































import Vue from "vue";
import Footer from "@/components/back-footer.vue";
import draggable from "vuedraggable";
export default Vue.extend({
  data: function () {
    return {
      prioritized: [
        {
          value: "Growth",
          sentence: "Accept growth",
        },
        {
          value: "Optimism",
          sentence: "Embrace optimism",
        },
        {
          value: "Love",
          sentence: "Spread love",
        },
        {
          value: "Mindfulness",
          sentence: "Be more mindful",
        },
        {
          value: "Balance",
          sentence: "Acknowledge balance",
        },
      ],
      drag: false,
    };
  },
  components: {
    Footer,
    draggable,
  },
  computed: {
    dragIcon: function () {
      return require("@/assets/img/grab.png");
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  mounted: function () {
    this.$store.dispatch("SetLogoStyle", { color: "dark" });
    this.$store.dispatch("SetLogoBottomStyle", { color: "light" });
  },

});
